import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";

function ActionsDropdown({ bracketId, isDeleting, disabled, onEdit, onViewStats, onDelete }) {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleIsOpened = () => setIsOpen((prev) => !prev);

    return (
        <>
            {isDeleting ? (
                <Button color="danger" size="sm" outline disabled>
                    Deleting…
                </Button>
            ) : (
                <ButtonDropdown isOpen={isOpen} toggle={toggleIsOpened}>
                    <DropdownToggle caret size="sm" color="secondary" outline disabled={disabled}>
                        Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                        {/* Edit Action */}
                        <DropdownItem onClick={() => onEdit(bracketId)}>Edit</DropdownItem>

                        {/* View in Stats Widget */}
                        <DropdownItem onClick={() => onViewStats(bracketId)}>
                            View in Stats Widget
                        </DropdownItem>

                        {/* Delete Action */}
                        <DropdownItem divider />
                        <DropdownItem className="text-danger" onClick={() => onDelete(bracketId)}>
                            Delete
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            )}
        </>
    );
}

ActionsDropdown.propTypes = {
    bracketId: PropTypes.string.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    onViewStats: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default ActionsDropdown;
