import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import ContentPanel from "@/components/ContentPanel";
import { Can } from "@/components/Ability";
import AbilityButton from "@/components/AbilityButton";
import { getStatsHref } from "@/lib/core/useOTP";

import "./style.scss";
import { useFeatureFlag } from "@/hooks/useFeatureFlag";

function SeasonFull({ id, otp }) {

  function goto(path){
    window.location.href = getStatsHref(path, otp)
  }

  const isEnabled = useFeatureFlag("tournament-brackets");

  return (
    <div className="season-full">
      <ContentPanel title="Games" to={`/seasons/${id}/games`}>
        <Button color="primary" tag={Link} to={`/seasons/${id}/games/completed`} size="sm" outline>
          View Completed Games
        </Button>

        <Button color="primary" tag={Link} to={`/seasons/${id}/games/scheduled`} size="sm" outline>
          View Schedule
        </Button>
        {isEnabled ? 
          <Button color="primary" tag={Link} to={`/seasons/${id}/games/brackets`} size="sm" outline>
            View Brackets
          </Button> : 
          null }
      </ContentPanel>

      <ContentPanel title="Season Stats">
        <Button color="primary" size="sm" outline onClick={() => goto(`/seasons/${id}/scores`)}>
          Scores
        </Button>

        <Button color="primary" size="sm" outline onClick={() => goto(`/seasons/${id}/schedule`)}>
          Schedule
        </Button>

        <Button color="primary" size="sm" outline onClick={() => goto(`/seasons/${id}/standings`)}>
          Standings
        </Button>

        <Button color="primary" size="sm" outline onClick={() => goto(`/seasons/${id}/players`)}>
          Players
        </Button>

        <Button color="primary" size="sm" outline onClick={() => goto(`/seasons/${id}/goalies`)}>
          Goalies
        </Button>
      </ContentPanel>

      <ContentPanel title="Season Divisions" to={`/seasons/${id}/divisions`}>
        <Button outline color="primary" size="sm" tag={Link} to={`/seasons/${id}/divisions`}>
          View Divisions
        </Button>

        <AbilityButton
          subject={{ type: "divisions", seasonId: id }}
          action="create"
          outline
          color="success"
          size="sm"
          tag={Link}
          to={`/seasons/${id}/divisions/new`}
        >
          + New Division
        </AbilityButton>
      </ContentPanel>

      <ContentPanel title="Season Teams" to={`/seasons/${id}/teams`}>
        <Button color="primary" tag={Link} to={`/seasons/${id}/teams`} size="sm" outline>
          View Teams
        </Button>

        <AbilityButton
          subject={{ type: "teams", seasonId: id }}
          action="create"
          color="success"
          tag={Link}
          to={`/seasons/${id}/teams/new`}
          size="sm"
          outline
        >
          + New Team
        </AbilityButton>
      </ContentPanel>

      <Can I="read" this={{ type: "roster", seasonId: id }}>
        <ContentPanel title="Season Roster" to={`/seasons/${id}/roster/players`}>
          <Button color="primary" tag={Link} to={`/seasons/${id}/roster/players`} size="sm" outline>
            View Season Roster
          </Button>

          <AbilityButton
            subject={{ type: "players", seasonId: id }}
            action="create"
            color="success"
            tag={Link}
            to={`/seasons/${id}/roster/players/new`}
            size="sm"
            outline
          >
            + New Player
          </AbilityButton>

          <AbilityButton
            subject={{ type: "coaches", seasonId: id }}
            action="create"
            color="success"
            tag={Link}
            to={`/seasons/${id}/roster/coaches/new`}
            size="sm"
            outline
          >
            + New Coach
          </AbilityButton>

          <AbilityButton
            subject={{ type: "roster", seasonId: id }}
            action="import"
            color="success"
            tag={Link}
            to={`/seasons/${id}/roster/import-from-csv`}
            size="sm"
            outline
          >
            Import from CSV
          </AbilityButton>
        </ContentPanel>
      </Can>

      <Can I="read" this={{ type: "referees", seasonId: id }}>
        <ContentPanel title="Referees" to={`/seasons/${id}/referees`}>
          <Button color="primary" tag={Link} to={`/seasons/${id}/referees`} size="sm" outline>
            View Referees
          </Button>

          <AbilityButton
            subject={{ type: "referees", seasonId: id }}
            action="create"
            color="success"
            tag={Link}
            to={`/seasons/${id}/referees/new`}
            size="sm"
            outline
          >
            + New Referee
          </AbilityButton>
        </ContentPanel>
      </Can>
    </div>
  );
}

SeasonFull.propTypes = {
  id: PropTypes.string.isRequired
};

export default SeasonFull;
