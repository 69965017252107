import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Button } from "reactstrap";

import FormItem from "@/components/FormItem";
import GamePeriodTimeInput from "@/components/GamePeriodTimeInput";

import PeriodInput from "../PeriodInput";
import TeamMemberInput from "../TeamMemberInput";
import TeamPlayerInput from "../TeamPlayerInput";
import PenaltyOffenderInput from "../PenaltyOffenderInput";
import PenaltyLengthInput from "../PenaltyLengthInput";
import PenaltyCodeInput from "../PenaltyCodeInput";

class Penalty extends PureComponent {
  static propTypes = {
    players: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    period: PropTypes.string,
    servedById: PropTypes.string,
    penalized: PropTypes.shape({}),
    length: PropTypes.string,
    code: PropTypes.string,
    offTime: PropTypes.string,
    startTime: PropTypes.string,
    onTime: PropTypes.string,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    onChange() {},
    onRemove() {}
  };

  handleChange = ({ field, value }) => {
    const { id, type, onChange } = this.props;

    onChange({ id, team: type, field, value });
  };

  handleFieldChange = ({ target: { name, value } }) => {
    this.handleChange({ field: name, value });
  };

  handleOffenderChange = ({ value: { type, id } }) => {
    this.handleChange({ field: "penalized", value: { type, id } });
  };

  handleRemove = () => {
    const { type, id, onRemove } = this.props;

    onRemove({ team: type, id });
  };

  render() {
    const { players, type, period, length, code, penalized, servedById, offTime, startTime, onTime } = this.props;
    const servedByPlayer = players.filter(p => p.id === servedById)[0] || "";
    const normalizedPeriod = period.startsWith('ot_') ? `OT${period.slice(3)}` : `${period}`; // Convert 'ot_1' to 'OT1'

    return (
      <FormItem data-period={normalizedPeriod} data-start-time={startTime} data-served-by-number={servedByPlayer.number}>
        <FormGroup className="col-md-1">
          <PeriodInput value={normalizedPeriod} name="period" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-2">
          <PenaltyOffenderInput value={penalized} team={type} onChange={this.handleOffenderChange} />
        </FormGroup>

        <FormGroup className="col-md-2">
          <TeamPlayerInput value={servedById} team={type} name="servedById" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1">
          <PenaltyLengthInput value={length} name="length" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-2">
          <PenaltyCodeInput value={code} name="code" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1">
          <GamePeriodTimeInput name="offTime" value={offTime} onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1">
          <GamePeriodTimeInput name="startTime" value={startTime} onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1">
          <GamePeriodTimeInput name="onTime" value={onTime} onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1 removal-btn-group">
          <Button outline color="danger" onClick={this.handleRemove}>
            &times;
          </Button>
        </FormGroup>
      </FormItem>
    );
  }
}

export default Penalty;
