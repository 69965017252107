import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Button } from "reactstrap";

import FormItem from "@/components/FormItem";
import GamePeriodTimeInput from "@/components/GamePeriodTimeInput";

import PeriodInput from "../PeriodInput";
import TeamPlayerInput from "../TeamPlayerInput";
import TeamGoalieInput from "../TeamGoalieInput";

class Goal extends PureComponent {
  static propTypes = {
    team: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    period: PropTypes.string,
    time: PropTypes.string,
    scorerId: PropTypes.string,
    assistAId: PropTypes.string,
    assistBId: PropTypes.string,
    goalieId: PropTypes.string,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    onChange() {},
    onRemove() {}
  };

  handleChange = ({ field, value }) => {
    const { id, team, onChange } = this.props;

    onChange({ id, team, field, value });
  };

  handleFieldChange = ({ target: { name, value } }) => {
    this.handleChange({ field: name, value });
  };

  handleRemove = () => {
    const { team, id, onRemove } = this.props;

    onRemove({ team, id });
  };

  render() {
    const { team, period, time, scorerId, assistAId, assistBId, goalieId } = this.props;
    const normalizedPeriod = period.startsWith('ot_') ? `OT${period.slice(3)}` : `${period}`; // Convert 'ot_1' to 'OT1'

    return (
      <FormItem data-period={normalizedPeriod} data-time={time}>
        <FormGroup className="col-md-1">
          <PeriodInput value={normalizedPeriod} name="period" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-1">
          <GamePeriodTimeInput name="time" value={time} onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-2">
          <TeamPlayerInput value={scorerId} team={team} name="scorerId" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-2">
          <TeamPlayerInput optional value={assistAId} team={team} name="assistAId" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-2">
          <TeamPlayerInput optional value={assistBId} team={team} name="assistBId" onChange={this.handleFieldChange} />
        </FormGroup>

        <FormGroup className="col-md-3">
          <TeamGoalieInput
            value={goalieId}
            team={team === "visitor" ? "home" : "visitor"}
            name="goalieId"
            onChange={this.handleFieldChange}
          />
        </FormGroup>

        <FormGroup className="col-md-1 removal-btn-group">
          <Button outline color="danger" onClick={this.handleRemove}>
            &times;
          </Button>
        </FormGroup>
      </FormItem>
    );
  }
}

export default Goal;
