import { config } from "@/config";
import { useState, useEffect } from "react";
import { firebase } from "@/firebase";

const useBracketList = ({ seasonId, otp }) => {
  const [bracketList, setBracketList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!seasonId) return;

    setLoading(true);
    setIsLoaded(false);

    const bracketsCollection = firebase.firestore().collection(`seasons/${seasonId}/brackets`);

    // Real-time listener
    const unsubscribe = bracketsCollection.onSnapshot(
      (snapshot) => {
        const brackets = snapshot.docs.map((doc) => {
          const data = doc.data();
          const gamesCount = Object.values(data.games || {}).filter((game) => game !== null).length;

          return {
            id: doc.id,
            title: data.title,
            games: gamesCount,
            createdAt: data.meta.createdAt.seconds ? new Date(data.meta.createdAt.seconds * 1000).toISOString() : null,
            updatedAt: data.meta.updatedAt.seconds ? new Date(data.meta.updatedAt.seconds * 1000).toISOString() : null,
          };
        });

        setBracketList(brackets);
        setIsLoaded(true);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching real-time bracket list:", error);
        setLoading(false);
      }
    );

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [seasonId]);

  const onEdit = (id) => {
    const bracketWindow = window.open(`${config.V5_ADMIN_DASHBOARD_URL}/seasons/${seasonId}/games/brackets/${id}`, "gamesheet-brackets");
    if (bracketWindow) {
      bracketWindow.focus();
    }
  };

  const onViewStats = (id) => {
    const url = `${config.STATS_WIDGET}/seasons/${seasonId}/brackets/${id}`;
    window.open(url, "gamesheet-statswidget");
  };

  const onDelete = async (id) => {
    setLoading(true);

    try {
      const bracketDoc = firebase.firestore().doc(`seasons/${seasonId}/brackets/${id}`);
      await bracketDoc.delete();

      // Firestore's onSnapshot will handle updating the local state automatically
    } catch (error) {
      console.error("Error deleting bracket:", error);
    } finally {
      setLoading(false);
    }
  };

  return { bracketList, loading, isLoaded, onEdit, onViewStats, onDelete };
};

export default useBracketList;
