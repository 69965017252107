import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function DeleteBracketsModal({ isOpen, close, clear, bracketName, onConfirmDelete }) {
    return (
      <Modal className="delete-brackets-modal" isOpen={isOpen} toggle={close} onClosed={clear} centered>
        <ModalHeader toggle={close}>Delete bracket</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete <strong>{bracketName}</strong>?</p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between' }}>
          <Button color="secondary" onClick={close}>Cancel</Button>
          <Button color="danger" onClick={onConfirmDelete}>Delete Bracket</Button>
        </ModalFooter>
      </Modal>
    );
  }
  

DeleteBracketsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  bracketName: PropTypes.string.isRequired
};

export default DeleteBracketsModal;
